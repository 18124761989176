import React from "react";
import { Socials } from "../Header-Footer/Data";
const Banner = () => {
  return (
    <main role="main">
      <div id="main">
        <div className="text-wrapper">
          <div className="lines">
            <div className="line"></div>
            <div className="line"></div>
            <div className="line"></div>
          </div>
          <div className="circle"></div>
          <div className="main-flex">
            <div className="image-self slide-in from-right">
              <img src="assets/image/neil-image.png" alt="" />
            </div>
            <div className="intro-head slide-in from-left">
              <div tabIndex="0">
                <h1>
                  <span>Hi,</span>{" "}
                  <span className="block name-span">I'm Neil</span>
                </h1>
                <p>
                  <span>a web developer</span> <span>based</span>{" "}
                  <span>in</span>
                  <span className="block">Toronto, Canada</span>
                </p>
              </div>
              <a
                href="assets/resume/Neil_Moras_Resume.pdf"
                className="btn"
                download
              >
                <i className="fa fa-download"></i>
                <span className="hidden">Click to Download</span>Resume
              </a>
            </div>
          </div>
          <div className="social-icon">
            {Socials.map((icon_social) => {
              const { id, url, icon, name } = icon_social;

              return (
                <a key={id} rel="noreferrer" href={url} target="_blank">
                  <span className="hidden">{name} link Opens in New Tab</span>
                  {icon}
                </a>
              );
            })}
          </div>
        </div>
      </div>
    </main>
  );
};

export default Banner;
