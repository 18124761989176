import React from "react";
import { NavLinks } from "./Data";

const Footer = () => {
  const year = new Date().getFullYear();

  return (
    <footer id="footer">
      <div className="text-wrapper">
        <div className="nav-flex-footer">
          <div className="copyright">
            &copy;Copyright, Neil Moras <span>@{year}</span>
          </div>
          <div className="nav-footer">
            <nav className="menu-footer">
              <h2 className="hidden">Footer Navigation Menu</h2>
              <ul>
                {NavLinks.map((link) => {
                  const { id, url, text } = link;
                  return (
                    <li key={id}>
                      <a className="nav-link btm-underline" href={url}>
                        {text}
                      </a>
                    </li>
                  );
                })}
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
