import React from "react";

const Button = ({ value, ref, url, name }) => {
  return (
    <a rel={ref} href={url}>
      {value}
      <span className="hidden">`Link Click to {name} on Github`</span>
    </a>
  );
};

export default Button;
