import React from "react";
import { CodeLanguages } from "../Header-Footer/Data";

const Skills = () => {
  return (
    <>
      <div id="code-banner"></div>
      <section id="skills-section" aria-labelledby="region2">
        <div className="text-wrapper">
          <div className="skill-heading">
            <h2 id="region2">Skills & Qualifications</h2>
          </div>
          <div className="skill-flex">
            <div className="front-container fade-in">
              <h3>Front-end</h3>
              <i className="fas fa-code"></i>
              <ul className="skill-list">
                <li>
                  <i aria-hidden="true" className="fas fa-check-circle"></i>
                  Responsive Design
                </li>
                <li>
                  <i aria-hidden="true" className="fas fa-check-circle"></i>
                  Git/Version Control
                </li>
                <li>
                  <i aria-hidden="true" className="fas fa-check-circle"></i>
                  Front End Frameworks
                </li>
                <li>
                  <i aria-hidden="true" className="fas fa-check-circle"></i>
                  Problem-Solving
                </li>
              </ul>
            </div>
            <div className="backend-container fade-in">
              <h3>Back-end</h3>
              <i className="fas fa-database"></i>
              <ul className="skill-list">
                <li>
                  <i aria-hidden="true" className="fas fa-check-circle"></i>
                  Accessibility & Security Compliance
                </li>
                <li>
                  <i aria-hidden="true" className="fas fa-check-circle"></i>
                  Database
                </li>
                <li>
                  <i aria-hidden="true" className="fas fa-check-circle"></i>
                  Frameworks
                </li>
                <li>
                  <i aria-hidden="true" className="fas fa-check-circle"></i>
                  Testing/Debugging
                </li>
              </ul>
            </div>
            <div className="edu-section-div fade-in">
              <h3>Educational Qualifications</h3>
              <div className="edu-div">
                <h4>
                  <span>Course:</span> Web Development
                </h4>
                <p>
                  <span>Institution:</span> Humber College of Applied Arts and
                  Technology, Ontario, Canada
                </p>
                <p>
                  <span>Year:</span> January 2021- December 2021
                </p>
              </div>
              <div className="edu-div">
                <h4>Course: Master of International Hotel Management</h4>
                <p>
                  <span>Institution:</span> Blue Mountains International Hotel
                  Management Institute, Sydney, Australia.
                </p>
                <p>
                  <span>University:</span> Torrens University
                </p>
                <p>
                  <span>Year:</span> 2014-2016
                </p>
              </div>
            </div>
          </div>
          <div className="skill-language-div">
            <div className="text-wrapper">
              <h3>Languages and Technologies</h3>
              <div className="language-flex">
                {CodeLanguages.map((language) => {
                  const { id, img_path, name } = language;

                  return <img key={id} src={img_path} alt={name} />;
                })}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Skills;
