import React from "react";
import { Socials } from "../Header-Footer/Data";
const Contact = () => {
  return (
    <section id="contact-section" aria-labelledby="region4">
      <div className="text-wrapper">
        <div className="contact-heading">
          <h2 id="region4" tabIndex="0">
            Contact Me
          </h2>
        </div>
        <div className="wrap">
          <div className="contact-frame">
            <div>
              <h3 className="hidden">Contact information.</h3>
              <p>
                <span className="contact-name-bold">Neil Moras</span>
                <span>Toronto,</span>
                <span>Ontario, Canada.</span>
                <span>Email: neilmoras@gmail.com</span>
              </p>
            </div>
            <div aria-hidden="true">
              <iframe
                title="Map Location of Toronto, Canada"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d184552.57289716916!2d-79.49891565188125!3d43.71815566221442!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89d4cb90d7c63ba5%3A0x323555502ab4c477!2sToronto%2C%20ON!5e0!3m2!1sen!2sca!4v1616985533025!5m2!1sen!2sca"
                width="600"
                height="450"
                // style="border:0;"
                allowFullScreen=""
                loading="lazy"
              ></iframe>
            </div>
          </div>
          <div className="container">
            <div className="text">
              <p>
                Hello! I’m interested in open opportunies to showcase my skills
                and learning.Please don’t hesitate to use this form.
              </p>
              <p id="my-form-status"></p>
            </div>
            <form
              id="my-form"
              action="https://formspree.io/f/xayakdyj"
              method="POST"
            >
              <h3 tabIndex="0" className="hidden">
                Contact Form
              </h3>
              <div className="form-row">
                <div className="input-data">
                  <input type="text" id="fullname" name="fullname" />
                  <label htmlFor="fullname">Name</label>
                </div>
                <div className="input-data row-block">
                  <input type="email" id="email" name="email" />
                  <label htmlFor="email">Email</label>
                </div>
              </div>
              <div className="form-row">
                <div className="input-data">
                  <input type="text" id="subject" name="subject" />
                  <label htmlFor="subject">Subject</label>
                </div>
              </div>
              <div className="form-row">
                <div className="input-data textarea">
                  <textarea
                    rows="8"
                    cols="80"
                    id="message"
                    name="message"
                  ></textarea>
                  <label htmlFor="message">Message</label>
                </div>
              </div>
              <div className="form-btn">
                <div className="input-data">
                  <button id="my-form-button">Submit</button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div className="icons">
          {Socials.map((icon_social) => {
            const { id, url, icon, name } = icon_social;

            return (
              <a key={id} rel="noreferrer" href={url} target="_blank">
                <span className="hidden">{name} link Opens in New Tab</span>
                {icon}
              </a>
            );
          })}
          {/* <a
            rel="noreferrer"
            href="https://www.linkedin.com/in/neil-moras-577a96a5/"
            target="_blank"
          >
            <span className="hidden">(Link opens in opens in new tab)</span>
            <i className="fab icon fa-linkedin-in"></i>
          </a>
          <a href="#my-form">
            <span className="hidden">Navigates to the Contact Form</span>
            <i className="far icon fa-envelope"></i>
          </a>
          <a
            rel="noreferrer"
            href="https://github.com/NeilMoras"
            target="_blank"
          >
            <span className="hidden">(Github link opens in new tab)</span>
            <i className="fab fa-github"></i>
          </a> */}
        </div>
      </div>
    </section>
  );
};

export default Contact;
