import React from "react";
import { FeaturedProjects } from "../Header-Footer/Data";
import Button from "./Button";

const Projects = () => {
  return (
    <section id="features-section" aria-labelledby="region3">
      <div className="text-wrapper">
        <div className="feature-heading">
          <h2 id="region3" tabIndex="0">
            Featured works
          </h2>
        </div>
        {FeaturedProjects.map((works, index) => {
          const alternatingRow = ["col-right", "col-left"];
          const {
            id,
            name,
            desc,
            img_src,
            type,
            video,
            languages,
            live,
            github,
          } = works;
          return (
            <div className="grid-flex" key={id}>
              <div className="col col-image slide-in from-left">
                <img src={img_src} alt={name} />
              </div>
              <div
                className={`col col-text slide-in from-left ${
                  alternatingRow[index % 2]
                }`}
              >
                <div className="aligner-item">
                  <div>
                    <h3>{name}</h3>
                    <p>{desc}</p>
                    <p>Project Type:{type}</p>
                    <p>Languages and technologies showcased: {languages}</p>
                  </div>
                  <Button value={"Github"} url={github} name={name}></Button>
                  <Button value={"Live"} url={live} name={name}></Button>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default Projects;
