import Header from "./components/Header-Footer/Header";
import Banner from "./components/Home/Banner";
import About from "./components/Home/About";
import Skills from "./components/Home/Skills";
import Projects from "./components/Home/Projects";
import Contact from "./components/Home/Contact";
import Footer from "./components/Header-Footer/Footer";

import "./App.css";

function App() {
  return (
    <>
      <Header />
      <Banner />
      <About />
      <Skills />
      <Projects />
      <Contact />
      <Footer />
    </>
  );
}

export default App;
