import React from "react";
import { NavLinks } from "./Data";
const Header = () => {
  return (
    <>
      <header id="header">
        <div className="text-wrapper">
          <div className="nav-flex">
            <div className="logo">
              <a href="#main">
                <img
                  id="web-logo"
                  src="assets/image/temp-logo.png"
                  alt="Logo of Neil's Portfolio website"
                />
              </a>
            </div>
            <nav id="menu" role="navigation">
              <span className="hidden">Navigation Menu</span>
              <i id="mob-exit" className="far fa-times-circle"></i>
              <ul>
                {NavLinks.map((link) => {
                  const { id, url, text } = link;
                  return (
                    <li key={id}>
                      <a href={url}>{text}</a>
                    </li>
                  );
                })}
              </ul>
            </nav>
            <div tabIndex="0" className="hamburger">
              <button
                id="ham-btn-cta"
                // role="navigation"
                // aria-expanded="false"
                className="menu-trigger"
              >
                <i className="fas fa-bars"></i>
                <span className="hidden">Main Menu Navigation</span>
              </button>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
