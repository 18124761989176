import React from "react";
export const NavLinks = [
  {
    id: 1,
    url: "#about-section",
    text: "about",
  },
  {
    id: 2,
    url: "#features-section",
    text: "portfolio",
  },
  {
    id: 3,
    url: "#contact-section",
    text: "contact",
  },
];

export const Socials = [
  {
    id: 1,
    url: "https://www.linkedin.com/in/neil-moras-577a96a5/",
    icon: <i className="fab fa-linkedin"></i>,
    name: "LinkedIn",
  },
  {
    id: 2,
    url: "https://github.com/NeilMoras",
    icon: <i className="fab fa-github"></i>,
    name: "Github",
  },
  {
    id: 3,
    url: "#contact-section",
    icon: <i className="fas fa-envelope"></i>,
    name: "Email",
  },
];

export const FeaturedProjects = [
  {
    id: 1,
    name: `Movies ${String.fromCharCode(43)} Soundtrack API App`,
    desc: "This application is part of an API integration project which consumes Trakt movies API and Deezer music API where you can view all the trnding movies and theri respective soundtracks all in one place.",
    img_src: "assets/image/movie-soundtrack-api.png",
    type: "Individual",
    languages: "HTML, CSS, PHP",
    live: "https://music-soundtrack-api.neilmoras.ca/",
    github: "https://github.com/NeilMoras/Movies_Soundtrack_Api_Project",
  },
  {
    id: 2,
    name: "Dragon Ball Semi-Interactive Animation",
    desc: " A semi interactive game created in order to showcase the skills and learning by using only HTML and CSS animations.",
    img_src: "assets/image/animation-project-img.png",
    type: "Individual",
    languages: "HTML, CSS, JavaScript",
    live: "https://animation-project.neilmoras.ca/",
    github: "https://github.com/NeilMoras/Animation_Project",
  },
  {
    id: 3,
    name: "Bets R Us Movie Betting Website",
    desc: "Our team Shamless Coders decided to create a betting website called “Bets R’ Us”. Users can bet on whether an upcoming unreleased movie will be a box office hit or flop. Users can also view information about actors and directors to help determine if they should bet hit or flop on a movie.",
    img_src: "/assets/image/betsrus-img.png",
    type: "Group",
    languages: "HTML, CSS, PHP",
    live: "https://betsrus.neilmoras.ca/",
    github: "https://github.com/Shameless-coders/Betting-R-Us",
  },
  {
    id: 4,
    name: "Ticketing System",
    desc: "This project was created with a focus on XML, were XPath is used to manipulate XML DOM elements where a unique ticket is issued to the user to the client where the admin and client can send messages back and forth to solve the issue.",
    img_src: "assets/image/ticket-system-img.png",
    type: "Individual",
    languages: "HTML, CSS, PHP",
    live: "http://ticket-system.neilmoras.ca/",
    github: "https://github.com/NeilMoras/Ticket_System",
  },
  {
    id: 5,
    name: "Fighter Jets World",
    desc: "A unique app created as part the Passion Project where the admin can Create, Read, Update and Delete information to keep the app dynamically updated based on CMS approach.",
    img_src: "assets/image/passion-project-img.png",
    type: "Individual",
    languages: "HTML, CSS, Javascript, ASP.NET C#.",
    live: "https://www.youtube.com/watch?v=qjP2lV7B7F0&list=UUQ97P3-uZxBZ7Rz5DKk770A&index=2",
    github: "https://github.com/NeilMoras/PassionProject_n01454501/tree/master",
  },
  {
    id: 6,
    name: "Code Snippet Sticky List",
    desc: " Application build as part of the pet project with the use of Vanilla JavaScript as the main scripting language where the user can store in code snippets of various languages as a reference for later and get back to it later. The each code in t he notes are color coded with the language icon as an identifier.",
    img_src: "assets/image/code-snippet-project-img.png",
    type: "Individual",
    languages: "HTML, CSS, Javascript",
    live: "http://code-snippet-storing-stick-notes.neilmoras.ca/",
    github: "https://github.com/NeilMoras/Code_snippet_stickynotes",
  },
];

export const CodeLanguages = [
  {
    id: 1,
    img_path: "assets/image/html.png",
    name: "HTML",
  },
  {
    id: 2,
    img_path: "assets/image/css.png",
    name: "CSS",
  },
  {
    id: 3,
    img_path: "assets/image/js.png",
    name: "JavaScript",
  },
  {
    id: 4,
    img_path: "assets/image/php.png",
    name: "PHP",
  },
  {
    id: 5,
    img_path: "assets/image/php-mysql.png",
    name: "PHP mySQL",
  },
  {
    id: 6,
    img_path: "assets/image/jqeury.png",
    name: "jQuery",
  },
  {
    id: 7,
    img_path: "assets/image/vue.js.png",
    name: "Vue.js",
  },
  {
    id: 8,
    img_path: "assets/image/c-sharp.png",
    name: "C#",
  },
  {
    id: 9,
    img_path: "assets/image/asp.net.jpeg",
    name: "ASP.NET",
  },
  {
    id: 10,
    img_path: "assets/image/react-native.png",
    name: "React Native",
  },
  {
    id: 11,
    img_path: "assets/image/react.png",
    name: "React.js",
  },
  {
    id: 12,
    img_path: "assets/image/sass.png",
    name: "Sass",
  },
  {
    id: 13,
    img_path: "assets/image/node.js.png",
    name: "Node.js",
  },
  {
    id: 14,
    img_path: "assets/image/wordpress.png",
    name: "WordPress",
  },
];
