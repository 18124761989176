import React from "react";

const About = () => {
  return (
    <section id="about-section" aria-labelledby="region1">
      <div className="text-wrapper">
        <div className="feature-heading">
          <h2 id="region1">About Me</h2>
        </div>
        <div className="about-flex">
          <div className="about-image-self">
            <img
              className="about-img"
              src="assets/image/about-me-self-image.jpg"
              alt="Neil Moras"
            />
          </div>
          <div className="about-head">
            <h3>Neil Moras</h3>
            <p>
              I am an aspiring Full-Stack Web Developer with an add-on of over
              six years of previous experience in Hotel Management. I am always
              curious to learn, develop and fix new things which comes in my way
              and developing websites and applications is what I enjoy always
              keeps me going . In my spare time I enjoy video games, cycling ,
              drawing, listening to music and long walks.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
